import css from './ActiveFilter.module.css';

const ActiveFilter = ({ name, onClose }) => {
  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <button className={css.active_filter} onClick={handleClose}>
      {name}
    </button>
  );
};

export default ActiveFilter;
