import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

//utils
import { scrollToTop } from '../../../utils';
import { convertSearchParams } from '../utils';

const useFilters = ({ resetPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState({
    genre: convertSearchParams(searchParams.get('genre')),
    author: convertSearchParams(searchParams.get('author')),
    narrator: convertSearchParams(searchParams.get('narrator')),
  });

  const updateSearchParams = (model, filters) => {
    setSearchParams((searchParams) => {
      if (filters.length) {
        searchParams.set([model], filters.join('-'));
      } else {
        searchParams.delete(model);
      }

      searchParams.delete('p');

      return searchParams;
    });
  };

  const removeSearchParams = () => {
    setSearchParams((searchParams) => {
      searchParams.delete('genre');
      searchParams.delete('author');
      searchParams.delete('narrator');
      searchParams.delete('p');

      return searchParams;
    });
  };

  const filtersChange = (model) => (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    let filtersByModel = [...selected[model]];

    if (isChecked) {
      filtersByModel.push(value);
    } else {
      filtersByModel = filtersByModel.filter((data) => data !== value);
    }

    setSelected((prev) => ({ ...prev, [model]: filtersByModel }));
    updateSearchParams(model, filtersByModel);
    resetPage();
    scrollToTop();
  };

  const resetFilters = () => {
    setSelected({ genre: [], author: [], narrator: [] });
    removeSearchParams();
    resetPage();
  };

  const removeFilter = (model, id) => () => {
    const filtersByModel = selected[model].filter((filter) => filter !== String(id));
    setSelected((prev) => ({ ...prev, [model]: filtersByModel }));
    updateSearchParams(model, filtersByModel);

    resetPage();
  };

  return { selected, filtersChange, resetFilters, removeFilter };
};

export default useFilters;
