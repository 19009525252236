import appRoutes from '../../config/appRoutes';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem('user');
  const location = useLocation();

  return user ? children : <Navigate to={appRoutes.HOME.url} state={{ from: location }} replace />;
};

export default ProtectedRoute;
