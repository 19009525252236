import { createAsyncThunk } from '@reduxjs/toolkit';
import apiRoutes from '../../../../config/apiRoutes';

//utils
import { customAxios, resolveError } from '../../../../utils';
import { getUnixTime } from 'date-fns';

export const login = createAsyncThunk(
  'authClient/login',
  async ({ login, remember }, { rejectWithValue }) => {
    try {
      await customAxios.get(apiRoutes.SANCTUM);
      await customAxios.post(apiRoutes.LOGIN, { login, remember });
      const { data } = await customAxios.get(apiRoutes.USER);
      const client = JSON.stringify({
        isAuth: true,
        voucherExpiration: data.voucher_expired_at,
      });
      localStorage.setItem('client', client);

      return data.voucher_expired_at;
    } catch (error) {
      const serverErrors = resolveError(error.response);

      return rejectWithValue(serverErrors);
    }
  }
);

export const logout = createAsyncThunk('authClient/logout', async () => {
  try {
    await customAxios.post(apiRoutes.LOGOUT, {});
    localStorage.removeItem('client');
  } catch (error) {
    console.log(resolveError(error.response));
  }
});

export const authCheckState = createAsyncThunk(
  'authClient/authCheckState',
  async (_, { dispatch }) => {
    const client = JSON.parse(localStorage.getItem('client'));

    if (client && client?.isAuth) {
      const expirationDate = client.voucherExpiration;
      const currentDate = getUnixTime(new Date());

      if (expirationDate > currentDate) {
        return client;
      } else {
        dispatch(logout);
      }
    }
  }
);
