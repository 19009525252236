import { humanizeDuration } from '../../../../utils';

//ui
import css from './BookDescription.module.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const BookDescription = ({ genre, author, narrator, duration, annotation }) => {
  return (
    <div className={css.box}>
      <div className={css.genre}>{genre}</div>
      <div className={css.info}>
        <div className={css.caption}>
          <PersonOutlineOutlinedIcon className={css.icon} />
          <span>{author}</span>
        </div>
        {narrator ? (
          <div className={css.caption}>
            <KeyboardVoiceOutlinedIcon className={css.icon} />
            <span>{narrator}</span>
          </div>
        ) : null}
        {duration ? (
          <div className={css.caption}>
            <AccessTimeOutlinedIcon className={css.icon} />
            <span>{humanizeDuration(duration)}</span>
          </div>
        ) : null}
      </div>
      <div className={css.description}>
        <h6 className={css.title}>Опис</h6>
        <p>{annotation}</p>
      </div>
    </div>
  );
};

export default BookDescription;
