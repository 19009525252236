import { useState, useEffect } from 'react';
import BooksService from '../services/BooksService';

//utils
import { convertBook } from '../utils';
import { resolveError } from '../../../../utils';

const useFetchBookById = (id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const { data } = await BooksService.getById(id);
        const book = convertBook(data.data);

        setData(book);
      } catch (error) {
        const serverErrors = resolveError(error.response);

        setError(serverErrors);
      } finally {
        setLoading(false);
      }
    };

    fetchBook();
  }, [id]);

  return { data, error, loading };
};

export default useFetchBookById;
