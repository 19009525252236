import { useState } from 'react';
import GenresService from '../services/GenresService';

//utils
import { resolveError } from '../../../../utils';

const ERROR_MESSAGE = 'Неможливо видалити жанр';

const useGenresQueries = (fetchGenres) => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);

  const createGenre = async (name) => {
    setStatus('pending');
    try {
      await GenresService.create(name);

      setStatus('fulfilled');
      fetchGenres();
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const updateGenre = async ({ id, name }) => {
    setStatus('pending');

    try {
      await GenresService.update({ id, name });

      setStatus('fulfilled');
      fetchGenres();
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const removeGenre = async (id) => {
    setStatus('pending');
    try {
      await GenresService.remove(id);
      setStatus('fulfilled');
      fetchGenres();
    } catch (error) {
      setStatus('rejected');
      setMessage(ERROR_MESSAGE);
    }
  };

  return { createGenre, updateGenre, removeGenre, status, message };
};

export default useGenresQueries;
