import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../../config/appRoutes';

//ui
import { Box, Tooltip, IconButton } from '@mui/material';
import { MaterialTable } from '../../../../components';
import { Delete, Edit } from '@mui/icons-material';

const BooksTable = ({ books, loading, isError, message, removeBook }) => {
  const navigate = useNavigate();
  const goToPage = (id) => () => navigate(appRoutes.BOOKS_EDIT(id).url);

  const handleRemoveBook = (id) => () => {
    const confirmed = window.confirm('Ви впевнені, що хочете видалити цю книгу?');

    if (confirmed) removeBook(id);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Назва',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <img src={row.original.imagePath} height={50} alt="preview" />
              <span>{renderedCellValue}</span>
            </Box>
          );
        },
      },
      {
        accessorKey: 'author.name',
        header: 'Автор',
      },
      {
        accessorKey: 'genre.name',
        header: 'Жанр',
      },
      {
        id: 'narrator',
        accessorFn: (row) => (row.narrator?.name ? row.narrator.name : '-'),
        header: 'Виконавець',
      },
      {
        accessorKey: 'isPublished',
        Cell: ({ cell }) => {
          const isPublished = cell.getValue();

          return (
            <span className={isPublished ? 'color-green' : 'color-red'}>
              {isPublished ? 'Так' : 'Ні'}
            </span>
          );
        },
        header: 'Опубліковано',
      },
    ],
    []
  );

  return (
    <MaterialTable
      enableRowActions
      data={books}
      columns={columns}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: message,
            }
          : undefined
      }
      state={{ isLoading: loading, showAlertBanner: isError }}
      renderRowActions={({ row }) => {
        return (
          <Box className="buttons-box">
            <Tooltip arrow placement="left" title="Редагувати">
              <IconButton onClick={goToPage(row.original.id)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Видалити">
              <IconButton color="error" onClick={handleRemoveBook(row.original.id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }}
    />
  );
};

export default BooksTable;
