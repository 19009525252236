import { memo } from 'react';

//ui
import AudioProgressBar from './AudioProgressBar';
import AudioEqualizer from './AudioEqualizer';
import AudioTimeDisplay from './AudioTimeDisplay';
import css from './AudioList.module.css';

const isEqualTrackIndex = (current, index) => current === index;

const AudioList = ({
  isPlaying,
  audioList,
  currentTime,
  duration,
  currentTrackIndex,
  handleSeek,
  handleTrackChange,
}) => {
  const handleChange = (index, currentTrackIndex) => () => {
    handleTrackChange(index, currentTrackIndex);
  };

  return (
    <div className={css.audio_list}>
      <div className={css.title}>Розділи</div>

      {audioList.map((track, index) => (
        <div
          tabIndex="0"
          className={`
          ${css.audio_list_item} 
          ${isEqualTrackIndex(index, currentTrackIndex) ? css.active : ''} 
 
          `}
          key={index}
          onClick={handleChange(index, currentTrackIndex)}>
          <div className={css.audio_list_box}>
            <AudioEqualizer isPlaying={isPlaying} />

            <span className={`${css.text} ${css.num}`}>0{index + 1}</span>

            <AudioTimeDisplay
              currentTime={currentTime}
              duration={track.durationSeconds}
              isUpdateTime={isEqualTrackIndex(index, currentTrackIndex)}
            />
          </div>
          {isEqualTrackIndex(index, currentTrackIndex) ? (
            <AudioProgressBar
              currentTime={currentTime}
              duration={duration}
              handleSeek={handleSeek}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default memo(AudioList);
