import apiRoutes from '../../../config/apiRoutes';
import { customAxios } from '../../../utils';

class BookCatalogService {
  static getBooks({ genre, author, narrator, page }) {
    return customAxios.get(apiRoutes.BOOKS(), {
      params: { genre, author, narrator, page },
    });
  }

  static getFilters({ genre, author, narrator }) {
    return customAxios.get(apiRoutes.filters, {
      params: { genre, author, narrator },
    });
  }
}

export default BookCatalogService;
