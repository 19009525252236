import { useState, useEffect } from 'react';
import AuthorsService from '../services/AuthorsService';

//utils
import { resolveError } from '../../../../utils';

const useFetchAuthors = () => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAuthors = async () => {
    setLoading(true);
    try {
      const { data } = await AuthorsService.getAll();

      setAuthors(data.data);
    } catch (error) {
      const serverErrors = resolveError(error.response);
      setError(serverErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  return { authors, error, loading, fetchAuthors };
};

export default useFetchAuthors;
