import { Zoom } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import css from '../UploadFiles.module.css';

const AttachedFilesPreview = ({ name, link }) => {
  return (
    <Zoom in={true}>
      <div className={css.preview_wrap}>
        <div className={css.preview_box}>
          <AttachFileIcon className={css.icon} />
          <a download rel="noreferrer" target="_blank" className={css.name} href={link}>
            {name}
          </a>
        </div>
      </div>
    </Zoom>
  );
};

export default AttachedFilesPreview;
