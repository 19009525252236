import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import appRoutes from '../../../config/appRoutes';

//modules
import { UploadFiles } from '../../../modules/UploadFiles';
import { UploadImage } from '../../../modules/UploadImage';

//hooks
import { useFetchTaxonomies, useBooksQueries, useFetchBookById } from './hooks';

//utils
import { extractBookValues, extractBookTracks } from './utils';

//ui
import { PageTitle, LinkComeBack, BlockSpinner, CustomSnackbar } from '../../../components';
import { BooksForm } from './components';
import css from './Books.module.css';

const EditBookPage = () => {
  const [image, setImage] = useState(null);
  const [tracks, setTracks] = useState([]);
  const { id } = useParams();
  const { genres, authors, narrators, loading } = useFetchTaxonomies();
  const { status, message, updateBook } = useBooksQueries({ image, tracks });
  const book = useFetchBookById(id);
  const values = useMemo(() => extractBookValues(book.data), [book.data]);
  const files = extractBookTracks(book.data?.tracks);

  return (
    <>
      <LinkComeBack to={appRoutes.BOOKS.url} />
      <PageTitle title={appRoutes.BOOKS_EDIT().name} />
      <div className={css.grid}>
        <BooksForm
          id={id}
          data={values}
          genres={genres}
          authors={authors}
          narrators={narrators}
          onSubmit={updateBook}
        />

        <aside>
          <div className={css.aside_box}>
            <div className={css.title}>Зображення</div>
            <div className={css.content}>
              <UploadImage file={image} setFile={setImage} preview={book.data?.imagePath} />
            </div>
          </div>
          <div className={css.aside_box}>
            <div className={css.title}>Аудіо файли</div>
            <div className={css.content}>
              <UploadFiles files={tracks} setFiles={setTracks} previews={files} />
            </div>
          </div>
        </aside>
      </div>
      {status === 'rejected' ? <CustomSnackbar text={message} /> : null}
      <BlockSpinner show={loading || book.loading || status === 'pending'} />
    </>
  );
};

export default EditBookPage;
