import apiRoutes from '../../../../config/apiRoutes';
import { customAxios } from '../../../../utils';

class GenresService {
  static getAll() {
    return customAxios.get(apiRoutes.GENRES);
  }

  static create(name) {
    return customAxios.post(apiRoutes.GENRES, { name });
  }

  static update({ id, name }) {
    return customAxios.put(apiRoutes.GENRES + '/' + id, { name });
  }

  static remove(id) {
    return customAxios.delete(apiRoutes.GENRES + '/' + id);
  }
}

export default GenresService;
