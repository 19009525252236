import { useState, useEffect } from 'react';
import BooksService from '../services/BooksService';

//utils
import { resolveError } from '../../../../utils';

const useFetchTaxonomies = () => {
  const [genres, setGenres] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [narrators, setNarrators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGenres = async () => {
    const { data } = await BooksService.getGenres();
    setGenres(data.data);
  };

  const fetchAuthors = async () => {
    const { data } = await BooksService.getAuthors();
    setAuthors(data.data);
  };

  const fetchNarrators = async () => {
    const { data } = await BooksService.getNarrators();
    setNarrators(data.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchGenres(), fetchAuthors(), fetchNarrators()]);
      } catch (error) {
        const serverErrors = resolveError(error.response);

        setError(serverErrors);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { genres, authors, narrators, loading, error };
};

export default useFetchTaxonomies;
