const apiRoutes = {
  SANCTUM: '/sanctum/csrf-cookie',
  LOGIN: '/login',
  LOGOUT: '/logout',
  USER: '/api/user',
  AUTHORS: '/api/authors',
  GENRES: '/api/genres',
  NARRATORS: '/api/narrators',
  AUDIO_BOOKS: '/api/audiobooks',
  filters: '/api/filters',
  BOOKS: (slug) => (slug ? `/api/books/${slug}` : '/api/books'),
  AUDIO_BOOK_LISTEN: (id) => `/api/listen/${id}`,
  AUDIO_BOOK_FILE: (bookId, trackId) => `/api/audiobooks/${bookId}/tracks/${trackId}`,
  GET_LISTENERS_COUNT: '/api/statistics/listeners-count',
};

export default apiRoutes;
