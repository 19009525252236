import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import css from './LinkComeBack.module.css';

const LinkComeBack = ({ to, title }) => {
  return (
    <Link to={to} className={css.link}>
      <ChevronLeftIcon />
      {title ?? 'Повернутися назад'}
    </Link>
  );
};

export default LinkComeBack;
