import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BookService from '../services/BookService';
import appRoutes from '../../../config/appRoutes';

//redux
import { useDispatch } from 'react-redux';
import { logout } from '../../../modules/Authentication';

//utils
import { resolveError } from '../../../utils';

const unauthorizedStatus = 401;

const useFetchBook = () => {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const { data } = await BookService.getByName(name);

        setBook(data.data);
      } catch (error) {
        const serverErrors = resolveError(error.response);
        setError(serverErrors);

        if (error.response?.status === unauthorizedStatus) {
          dispatch(logout());
          navigate(appRoutes.HOME.url);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBook();
  }, [name, dispatch, navigate]);

  return { book, loading, error };
};

export default useFetchBook;
