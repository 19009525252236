//hooks
import useUploadImage from './hooks/useUploadImage';

//ui
import UploadImagePreview from './components/UploadImagePreview';
import UploadImageButton from './components/UploadImageButton';
import css from './UploadImage.module.css';

const UploadImage = ({ file, setFile, preview }) => {
  const { dragActive, handleFileChange, handleDrag, handleDrop } = useUploadImage({
    file,
    setFile,
  });
  const imgUrl = file?.preview || preview;

  return (
    <div className={`${css.container} ${dragActive ? css.active : ''}`} onDragEnter={handleDrag}>
      <label className={css.label}>
        <input accept="image/*" type="file" onChange={handleFileChange} className="d-none" />
        <span className={css.wrap}>
          <div className={css.select}>
            {imgUrl ? <UploadImagePreview url={imgUrl} /> : <UploadImageButton />}
          </div>
        </span>
      </label>
      {dragActive ? (
        <div
          className={css.drag_element}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      ) : null}
    </div>
  );
};

export default UploadImage;
