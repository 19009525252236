//redux
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../modules/Authentication/slices/auth';

//ui
import { Typography, Skeleton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import css from './Sidebar.module.css';

const SidebarProfile = () => {
  const { user, loading } = useSelector(selectAuth);
  const name = user?.name ?? '-';

  return (
    <div className={css.profile}>
      <AccountCircleIcon className={css.icon} />

      <Typography variant="h6">{loading ? <Skeleton className={css.skeleton} /> : name}</Typography>
    </div>
  );
};

export default SidebarProfile;
