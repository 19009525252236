import { useNavigate } from 'react-router-dom';
import { humanizeDuration, textCutting } from '../../../../utils';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { authClientActions } from '../../../../modules/Authentication';

//ui
import css from './BookItem.module.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const BookItem = (props) => {
  const { title, slug, annotation, duration, genre, narrator, imagePath, author } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = useSelector((state) => state.authClient);

  const handleGoToPage = () => {
    if (client.isAuth) {
      navigate(`books/${slug}`);
    } else {
      dispatch(authClientActions.setShow(true));
    }
  };

  return (
    <div className={css.card} onClick={handleGoToPage}>
      <div className={css.preview}>
        <img src={imagePath} alt="preview" width="185" height="185" loading="lazy" />
      </div>
      <div className={css.links}>
        <div className={css.genre}>{genre.name}</div>
        <div className={css.title}>{title}</div>
      </div>
      <div className={css.description}>{textCutting(annotation)}</div>
      <div className={css.info}>
        <div className={css.caption}>
          <PersonOutlineOutlinedIcon className={css.icon} />
          <span>{author.name}</span>
        </div>
        {narrator?.name ? (
          <div className={css.caption}>
            <KeyboardVoiceOutlinedIcon className={css.icon} />
            <span>{narrator.name}</span>
          </div>
        ) : null}
        {duration ? (
          <div className={css.caption}>
            <AccessTimeOutlinedIcon className={css.icon} />
            <span>{humanizeDuration(duration)}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BookItem;
