import { useState } from 'react';
import { Outlet } from 'react-router-dom';

//ui
import { Header } from './components';
import { AuthDialogForm } from '../../modules/Authentication';
import { LegalModal } from '../../modules/LegalModal';

const MainLayout = () => {
  const [openLegal, setOpenLegal] = useState(false);

  const handleOpenLegal = () => setOpenLegal(true);

  return (
    <>
      <Header onOpenLegal={handleOpenLegal} />
      <main>
        <Outlet />
      </main>
      <AuthDialogForm />
      <LegalModal open={openLegal} setOpen={setOpenLegal} />
    </>
  );
};

export default MainLayout;
