import { useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

//utils
import { scrollToTop } from '../../../utils';

const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(+searchParams.get('p') || 1);

  const handlePageChange = useCallback(
    (_, page = 1) => {
      setPage(page);
      scrollToTop();

      setSearchParams((searchParams) => {
        if (page > 1) {
          searchParams.set('p', page);
        } else {
          searchParams.delete('p');
        }

        return searchParams;
      });
    },
    [setSearchParams]
  );

  const resetPage = useCallback(() => setPage(1), []);

  return { page, handlePageChange, resetPage };
};

export default usePagination;
