import { useState, useEffect, memo } from 'react';
import { TextField, Autocomplete, Switch, FormControlLabel, Button } from '@mui/material';
import css from '../Books.module.css';

const BooksForm = ({ id, data, genres, authors, narrators, onSubmit }) => {
  const [values, setValues] = useState({
    title: '',
    annotation: '',
    author: null,
    genre: null,
    narrator: null,
    isPublished: false,
  });
  const { title, annotation, author, genre, narrator, isPublished } = values;

  const handleChangeText = (e) => {
    const { value, name } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSelect = (name) => (_, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSwitch = (e) => {
    const { checked, name } = e.target;

    setValues((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ id, values });
  };

  useEffect(() => {
    if (data) setValues(data);
  }, [data]);

  return (
    <form className={css.form} onSubmit={handleSubmit}>
      <TextField
        fullWidth
        required
        value={title}
        name="title"
        className={css.indent}
        variant="outlined"
        label="Назва"
        onChange={handleChangeText}
      />

      <Autocomplete
        fullWidth
        value={genre}
        onChange={handleChangeSelect('genre')}
        className={css.indent}
        options={genres}
        getOptionLabel={({ name }) => name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="Немає даних"
        renderInput={(params) => <TextField required {...params} label="Жанр" />}
      />
      <Autocomplete
        fullWidth
        value={author}
        onChange={handleChangeSelect('author')}
        className={css.indent}
        options={authors}
        getOptionLabel={({ name }) => name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="Немає даних"
        renderInput={(params) => <TextField required {...params} label="Автор" />}
      />
      <Autocomplete
        fullWidth
        value={narrator}
        onChange={handleChangeSelect('narrator')}
        className={css.indent}
        options={narrators}
        getOptionLabel={({ name }) => name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="Немає даних"
        renderInput={(params) => <TextField {...params} label="Виконавець" />}
      />
      <TextField
        fullWidth
        required
        multiline
        value={annotation}
        name="annotation"
        className={css.indent}
        variant="outlined"
        label="Опис"
        minRows={5}
        maxRows={15}
        onChange={handleChangeText}
      />

      <FormControlLabel
        control={<Switch name="isPublished" checked={isPublished} onChange={handleChangeSwitch} />}
        label="Опублікувати"
      />

      <Button type="submit" variant="contained" color="success" className={css.button}>
        Зберегти
      </Button>
    </form>
  );
};

export default memo(BooksForm);
