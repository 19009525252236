import { Link } from 'react-router-dom';
import appRoutes from '../../../../config/appRoutes';

//ui
import { AppBar, Toolbar, IconButton, Hidden } from '@mui/material';

//icons
import logo from '../../../../assets/icons/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import css from './Header.module.css';

const Header = ({ onSidebarOpen }) => {
  return (
    <AppBar>
      <Toolbar className={css.toolbar}>
        <Link to={appRoutes.BOOKS.url}>
          <img src={logo} alt="logo" />
        </Link>
        <div className={css.full_width}></div>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
