import { useState } from 'react';
import appRoutes from '../../../config/appRoutes';

//hooks
import { useFetchGenres, useGenresQueries } from './hooks';

//ui
import { PageTitle, CustomSnackbar } from '../../../components';
import { GenresDialog, GenresTable } from './components';
import { Button } from '@mui/material';

const GenresPage = () => {
  const [open, setOpen] = useState(false);
  const [genre, setGenre] = useState(null);
  const { genres, loading, error, fetchGenres } = useFetchGenres();
  const { createGenre, updateGenre, removeGenre, status, message } = useGenresQueries(fetchGenres);

  const handleOpenDialog = (genre) => {
    setOpen(true);

    if (genre?.id) {
      const { id, name } = genre;
      setGenre({ id, name });
    } else {
      setGenre(null);
    }
  };

  return (
    <>
      <PageTitle title={appRoutes.GENRES.name} />
      <Button
        variant="contained"
        color="success"
        className="button-indents"
        onClick={handleOpenDialog}>
        Додати
      </Button>
      <GenresTable
        genres={genres}
        loading={loading}
        error={error}
        removeGenre={removeGenre}
        openDialog={handleOpenDialog}
      />

      {open ? (
        <GenresDialog
          open={open}
          setOpen={setOpen}
          genre={genre}
          createGenre={createGenre}
          updateGenre={updateGenre}
          status={status}
        />
      ) : null}

      {status === 'rejected' ? <CustomSnackbar text={message} /> : null}
    </>
  );
};

export default GenresPage;
