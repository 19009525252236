const resolveError = (error, defaultErrorMessage = 'Помилка сервера') => {
  const errorResponse = error?.data;

  if (error?.status === 500) return defaultErrorMessage;

  if (errorResponse?.error) {
    return handleErrorResponse(errorResponse?.error);
  }

  if (errorResponse?.errors) return handleErrorResponse(errorResponse?.errors);

  if (errorResponse?.message) {
    return errorResponse?.trace ? defaultErrorMessage : errorResponse.message;
  }

  if (errorResponse?.status) return handleErrorResponse(errorResponse?.status);

  return defaultErrorMessage;
};

function handleErrorResponse(error) {
  if (typeof error === 'object') {
    return Object.values(error).join('; ');
  } else if (typeof error === 'string') {
    return error;
  }

  return 'Помилка сервера';
}

export default resolveError;
