import { useMemo, memo } from 'react';
import css from './AudioList.module.css';

const AudioProgressBar = ({ currentTime, duration, handleSeek }) => {
  const progress = useMemo(() => (currentTime / duration) * 100, [currentTime, duration]);

  const handleChange = (e) => handleSeek(e.target.value);

  return (
    <>
      <div className={css.progress} style={{ width: progress + '%' }} />
      <input
        tabIndex="-1"
        className={css.hidden_slider}
        type="range"
        onChange={handleChange}
        value={currentTime}
        max={Math.round(duration)}
      />
    </>
  );
};

export default memo(AudioProgressBar);
