import { AccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import css from './FilterItem.module.css';

const FilterItemAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<KeyboardArrowRightRoundedIcon className={css.icon} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  padding: 0,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '3px',
  },
}));

export default FilterItemAccordionSummary;
