import { ActiveFilter, ResetFiltersButton } from '../../components';
import { useMediaQuery } from '@mui/material';
import SettingsIcon from './icons/SettingsIcon';
import css from './Toolbar.module.css';

const Toolbar = ({ setShow, activeFilters, onFilterRemove, onFiltersReset }) => {
  const handleShow = () => setShow(true);
  const isMobile = useMediaQuery('(max-width:768px)');

  if (activeFilters.length === 0 && !isMobile) return null;

  return (
    <div className={css.toolbar}>
      <button className={css.mobile_filter} onClick={handleShow}>
        <SettingsIcon />
      </button>

      <div className={css.active_filters}>
        {activeFilters.map((filter) => (
          <ActiveFilter
            key={filter.model + filter.id}
            name={filter.name}
            onClose={onFilterRemove(filter.model, filter.id)}
          />
        ))}

        <ResetFiltersButton onFiltersReset={onFiltersReset} />
      </div>
    </div>
  );
};

export default Toolbar;
