import { Link } from 'react-router-dom';

//ui
import { Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import css from './Breadcrumbs.module.css';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const Breadcrumbs = ({ genre, title }) => {
  return (
    <div role="presentation" className={css.wrap}>
      <MuiBreadcrumbs
        separator={<KeyboardArrowRightOutlinedIcon className={css.icon} />}
        aria-label="breadcrumb">
        <Link to="/" className={css.text}>
          Головна
        </Link>
        <Link to={`/?genre=${genre.id}`} className={css.text}>
          {genre.name}
        </Link>
        <Typography className={css.text}>{title}</Typography>
      </MuiBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
