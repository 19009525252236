import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';

//redux
import { useDispatch } from 'react-redux';
import { authCheckState } from './modules/Authentication';

import './styles/main.css';

const App = () => {
  const routing = useRoutes(routes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  return <div>{routing}</div>;
};

export default App;
