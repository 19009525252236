import { useState, useEffect, useCallback } from 'react';
import BookCatalogService from '../services/BookCatalogService';

//utils
import { resolveError } from '../../../utils';

const ERROR_MESSAGE = 'Вибачте, по вашому запиту нічого не знайдено';

const useFetchBooks = (selected, page) => {
  const [books, setBooks] = useState([]);
  const [loadingBooks, setLoadingBooks] = useState(true);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const { genre, narrator, author } = selected;

  const fetchBooks = useCallback(async ({ genre, author, narrator, page }) => {
    setLoadingBooks(true);

    try {
      const { data } = await BookCatalogService.getBooks({ genre, author, narrator, page });
      const books = data.data;

      setBooks(books);
      setTotalPage(data.meta.last_page);
      books.length === 0 ? setError(ERROR_MESSAGE) : setError(null);
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setError(serverErrors);
    } finally {
      setLoadingBooks(false);
    }
  }, []);

  useEffect(() => {
    fetchBooks({ genre, narrator, author, page });
  }, [genre, narrator, author, page, fetchBooks]);

  useEffect(() => {
    const handleHistoryBack = () => {
      window.location.reload();
    };

    window.addEventListener('popstate', handleHistoryBack);

    return () => {
      window.removeEventListener('popstate', handleHistoryBack);
    };
  }, []);

  return { books, loadingBooks, totalPage, error };
};

export default useFetchBooks;
