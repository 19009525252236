import apiRoutes from '../../../../config/apiRoutes';
import { customAxios } from '../../../../utils';

class AuthorsService {
  static getAll() {
    return customAxios.get(apiRoutes.AUTHORS);
  }

  static create(name) {
    return customAxios.post(apiRoutes.AUTHORS, { name });
  }

  static update({ id, name }) {
    return customAxios.put(apiRoutes.AUTHORS + '/' + id, { name });
  }

  static remove(id) {
    return customAxios.delete(apiRoutes.AUTHORS + '/' + id);
  }
}

export default AuthorsService;
