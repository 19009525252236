import { useState } from 'react';
import AuthorsService from '../services/AuthorsService';

//utils
import { resolveError } from '../../../../utils';

const ERROR_MESSAGE = 'Неможливо видалити автора';

const useAuthorsQueries = (fetchAuthors) => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);

  const createAuthor = async (name) => {
    setStatus('pending');
    try {
      await AuthorsService.create(name);

      setStatus('fulfilled');
      fetchAuthors();
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const updateAuthor = async ({ id, name }) => {
    setStatus('pending');
    try {
      await AuthorsService.update({ id, name });

      setStatus('fulfilled');
      fetchAuthors();
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const removeAuthor = async (id) => {
    setStatus('pending');

    try {
      await AuthorsService.remove(id);
      setStatus('fulfilled');
      fetchAuthors();
    } catch (error) {
      setStatus('rejected');
      setMessage(ERROR_MESSAGE);
    }
  };

  return { createAuthor, updateAuthor, removeAuthor, status, message };
};

export default useAuthorsQueries;
