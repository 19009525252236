import appRoutes from '../../config/appRoutes';
import { Navigate, useLocation } from 'react-router-dom';

//redux
import { useSelector } from 'react-redux';

const ProtectedLoginRoute = ({ children }) => {
  const client = useSelector((state) => state.authClient);
  const location = useLocation();

  return !client.isAuth ? (
    children
  ) : (
    <Navigate to={appRoutes.HOME.url} state={{ from: location }} replace />
  );
};

export default ProtectedLoginRoute;
