//redux
import { useDispatch, useSelector } from 'react-redux';
import { authClientActions } from '../slices/authClient';
import { login } from '../slices/authClient/authClientActions';

//ui
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '../../../components';

import css from './AuthDialogForm.module.css';

const AuthDialogForm = () => {
  const dispatch = useDispatch();
  const isFullScreen = useMediaQuery('(max-width:414px)');
  const client = useSelector((state) => state.authClient);
  const { show, error, loading, values } = client;
  const disableButton = !values.code || loading;

  const handleValuesChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === 'checkbox') {
      dispatch(authClientActions.setValues({ name, value: checked }));
    } else {
      dispatch(authClientActions.setValues({ name, value }));
    }
  };

  const handleClose = () => {
    dispatch(authClientActions.setShow(false));
  };

  const handleAuth = () => {
    const { code, remember } = values;

    dispatch(login({ login: code.trim(), remember }));
  };

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={show}
      onClose={handleClose}
      PaperProps={{ classes: { root: css.dialog } }}>
      <DialogTitle className={css.title}>
        Увійти
        <button aria-label="Згорнути" className={css.close_btn} onClick={handleClose} />
      </DialogTitle>
      <DialogContent className={css.content}>
        <DialogContentText className={css.text}>
          Шановний користувачу, для насолоди прослуховування аудіокнигами на нашому сайті потрібно
          авторизуватися. Для входу в бібліотеку введіть код, що надрукований у чеку.
        </DialogContentText>
        <TextField
          fullWidth
          error={Boolean(error)}
          helperText={error}
          name="code"
          color="secondary"
          size="small"
          variant="outlined"
          label="Код доступу"
          value={values.code}
          onChange={handleValuesChange}
          className={css.input}
        />
        <FormControlLabel
          className={css.label}
          control={
            <Checkbox
              name="remember"
              classes={{ root: css.checkbox }}
              color="secondary"
              value={values.remember}
              onChange={handleValuesChange}
            />
          }
          label="Запам’ятати код"
        />

        <LoadingButton
          fullWidth
          disabled={disableButton}
          loading={loading}
          onClick={handleAuth}
          className={css.button}
          name="Увійти"
          color="primary"
          variant="contained"
        />
        <Button
          fullWidth
          className={`${css.button} ${css.button_later}`}
          onClick={handleClose}
          color="secondary">
          Пізніше
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialogForm;
