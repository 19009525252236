import React from 'react';
import appRoutes from '../../../config/appRoutes';

//hooks
import { useNavigate } from 'react-router-dom';
import { useFetchBooks, useBooksQueries } from './hooks';

//ui
import { PageTitle } from '../../../components';
import { BooksTable } from './components';
import { Button } from '@mui/material';

const BooksPage = () => {
  const navigate = useNavigate();
  const { books, loading, isError, message, fetchBooks } = useFetchBooks();
  const { removeBook } = useBooksQueries({ fetchBooks });
  const goToPage = () => navigate(appRoutes.BOOKS_CREATE.url);

  return (
    <>
      <PageTitle title={appRoutes.BOOKS.name} />
      <Button variant="contained" color="success" className="button-indents" onClick={goToPage}>
        Додати
      </Button>
      <BooksTable
        books={books}
        loading={loading}
        isError={isError}
        message={message}
        removeBook={removeBook}
      />
    </>
  );
};

export default BooksPage;
