const extractBookTracks = (tracks) => {
  if (!tracks) return null;

  return tracks.map((file) => {
    return {
      name: file.originalFileName,
      link: file.track,
    };
  });
};

export default extractBookTracks;
