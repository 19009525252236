import { useState } from 'react';
import { getActiveGenre } from './utils';

//hooks
import { useFetchBooks, useFetchFilters, useFilters, usePagination } from './hooks';

//ui
import { BlockSpinner, PageTitle, ErrorMessage } from '../../components';
import { Filters, Toolbar, BookItem } from './components';
import { Pagination } from '@mui/material';

import css from './BookCatalogPage.module.css';

const BookCatalogPage = () => {
  const [show, setShow] = useState(false);
  const { page, handlePageChange, resetPage } = usePagination();
  const { selected, filtersChange, removeFilter, resetFilters } = useFilters({
    resetPage,
  });
  const { books, loadingBooks, totalPage, error } = useFetchBooks(selected, page);
  const { data, loadingFilters } = useFetchFilters(selected);
  const showPagination = totalPage > 1;
  const showPageLoader = !data.filters?.genres?.length && !books.length && !error;
  const activeGenre = getActiveGenre(data.active);
  const title = activeGenre ?? 'АЛЛО Бібліотека';

  if (showPageLoader) return <BlockSpinner show />;

  return (
    <div className={css.catalog}>
      <div className="container">
        <PageTitle title={title} />
        <div className={css.grid}>
          <div className={css.navigation}>
            <Filters
              show={show}
              filtersData={data}
              selectedFilters={selected}
              loading={loadingFilters}
              onFiltersChange={filtersChange}
              onFilterRemove={removeFilter}
              onFiltersReset={resetFilters}
              setShow={setShow}
            />
          </div>
          <div className={css.content}>
            <div className={css.toolbar}>
              <Toolbar
                setShow={setShow}
                activeFilters={data.active}
                onFilterRemove={removeFilter}
                onFiltersReset={resetFilters}
              />
            </div>
            <div className={css.products}>
              {books.map((book) => (
                <BookItem {...book} key={book.id} />
              ))}

              {error ? <ErrorMessage error={error} /> : null}

              {showPagination ? (
                <Pagination
                  className={css.pagination}
                  shape="rounded"
                  page={page}
                  count={totalPage}
                  hidePrevButton={page === 1}
                  hideNextButton={totalPage === page}
                  onChange={handlePageChange}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <BlockSpinner show={loadingBooks || loadingFilters} />
    </div>
  );
};

export default BookCatalogPage;
