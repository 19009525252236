import { formatDuration } from 'date-fns';
import { uk } from 'date-fns/locale';

const humanizeDuration = (seconds) => {
  const options = { locale: uk };

  if (seconds < 60) {
    return formatDuration({ seconds }, options);
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return formatDuration({ minutes, seconds: remainingSeconds }, options);
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);

    return formatDuration({ hours, minutes: remainingMinutes }, options);
  } else {
    const days = Math.floor(seconds / 86400);
    const remainingHours = Math.floor((seconds % 86400) / 3600);

    return formatDuration({ days, hours: remainingHours }, options);
  }
};

export default humanizeDuration;
