import { useState } from 'react';
import NarratorsService from '../services/NarratorsService';

//utils
import { resolveError } from '../../../../utils';

const ERROR_MESSAGE = 'Неможливо видалити виконавця';

const useNarratorsQueries = (fetchNarrators) => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);

  const createNarrator = async (name) => {
    setStatus('pending');
    try {
      await NarratorsService.create(name);

      setStatus('fulfilled');
      fetchNarrators();
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const updateNarrator = async ({ id, name }) => {
    setStatus('pending');

    try {
      await NarratorsService.update({ id, name });
      setStatus('fulfilled');
      fetchNarrators();
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const removeNarrator = async (id) => {
    setStatus('pending');
    try {
      await NarratorsService.remove(id);
      setStatus('fulfilled');
      fetchNarrators();
    } catch (error) {
      setStatus('rejected');
      setMessage(ERROR_MESSAGE);
    }
  };

  return { createNarrator, updateNarrator, removeNarrator, status, message };
};

export default useNarratorsQueries;
