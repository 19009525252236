import { useState } from 'react';
import appRoutes from '../../../config/appRoutes';

//hooks
import { useFetchAuthors, useAuthorsQueries } from './hooks';

//ui
import { PageTitle, CustomSnackbar } from '../../../components';
import { AuthorsTable, AuthorsDialog } from './components';
import { Button } from '@mui/material';

const AuthorsPage = () => {
  const [open, setOpen] = useState(false);
  const [author, setAuthor] = useState(null);
  const { authors, loading, error, fetchAuthors } = useFetchAuthors();
  const { createAuthor, updateAuthor, removeAuthor, status, message } =
    useAuthorsQueries(fetchAuthors);

  const handleOpenDialog = (author) => {
    setOpen(true);

    if (author?.id) {
      const { id, name } = author;
      setAuthor({ id, name });
    } else {
      setAuthor(null);
    }
  };

  return (
    <>
      <PageTitle title={appRoutes.AUTHORS.name} />
      <Button
        variant="contained"
        color="success"
        className="button-indents"
        onClick={handleOpenDialog}>
        Додати
      </Button>
      <AuthorsTable
        authors={authors}
        loading={loading}
        error={error}
        removeAuthor={removeAuthor}
        openDialog={handleOpenDialog}
      />

      {open ? (
        <AuthorsDialog
          open={open}
          setOpen={setOpen}
          author={author}
          createAuthor={createAuthor}
          updateAuthor={updateAuthor}
          status={status}
        />
      ) : null}

      {status === 'rejected' ? <CustomSnackbar text={message} /> : null}
    </>
  );
};

export default AuthorsPage;
