import { NavLink, useNavigate } from 'react-router-dom';
import appRoutes from '../../../../config/appRoutes';

//redux
import { useDispatch } from 'react-redux';
import { logout } from '../../../../modules/Authentication/slices/auth/authActions';

//ui
import { List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';

//icons
import InputIcon from '@mui/icons-material/Input';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

import css from './Sidebar.module.css';

const SidebarNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout()).then(() => navigate(appRoutes.ADMIN.url));
  };

  return (
    <List>
      <ListItemButton className={css.list_item}>
        <NavLink to={appRoutes.BOOKS.url} className={`nav-link ${css.list_link}`}>
          <ListItemIcon className={`nav-icon ${css.list_icon}`}>
            {<LibraryBooksIcon />}
          </ListItemIcon>
          <ListItemText className={`nav-title ${css.list_title}`} primary={appRoutes.BOOKS.name} />
        </NavLink>
      </ListItemButton>

      <ListItemButton className={css.list_item}>
        <NavLink to={appRoutes.GENRES.url} className={`nav-link ${css.list_link}`}>
          <ListItemIcon className={`nav-icon ${css.list_icon}`}>
            {<TheaterComedyIcon />}
          </ListItemIcon>
          <ListItemText className={`nav-title ${css.list_title}`} primary={appRoutes.GENRES.name} />
        </NavLink>
      </ListItemButton>

      <ListItemButton className={css.list_item}>
        <NavLink to={appRoutes.AUTHORS.url} className={`nav-link ${css.list_link}`}>
          <ListItemIcon className={`nav-icon ${css.list_icon}`}>{<PeopleIcon />}</ListItemIcon>
          <ListItemText
            className={`nav-title ${css.list_title}`}
            primary={appRoutes.AUTHORS.name}
          />
        </NavLink>
      </ListItemButton>

      <ListItemButton className={css.list_item}>
        <NavLink to={appRoutes.NARRATORS.url} className={`nav-link ${css.list_link}`}>
          <ListItemIcon className={`nav-icon ${css.list_icon}`}>{<PeopleAltIcon />}</ListItemIcon>
          <ListItemText
            className={`nav-title ${css.list_title}`}
            primary={appRoutes.NARRATORS.name}
          />
        </NavLink>
      </ListItemButton>

      <ListItemButton className={css.list_item} onClick={handleLogout}>
        <div className={css.list_link}>
          <ListItemIcon className={css.list_icon}>{<InputIcon />}</ListItemIcon>
          <ListItemText className={css.list_title} primary={'Вийти'} />
        </div>
      </ListItemButton>
    </List>
  );
};

export default SidebarNavigation;
