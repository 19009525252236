//modules
import { AudioPlayer } from '../../modules/AudioPlayer';
import useFetchBook from './hooks/useFetchBook';

//utils
import { convertTracks } from './utils';

//ui
import { Breadcrumbs, PageTitle, BlockSpinner, ErrorMessage } from '../../components';
import { BookDescription } from './components';
import css from './BookPage.module.css';

const BookPage = () => {
  const { book, loading, error } = useFetchBook();

  if (loading) return <BlockSpinner show />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <div className="container">
        <Breadcrumbs genre={book.genre} title={book.title} />
        <PageTitle title={book.title} />
      </div>
      <div className={css.content}>
        <div className="container">
          <div className={css.grid}>
            <div className={css.preview}>
              <img src={book.imagePath} alt="preview" width="500" height="500" />
            </div>
            {book?.tracks?.length > 0 ? (
              <div className={css.player}>
                <AudioPlayer audioList={convertTracks(book.tracks)} />
              </div>
            ) : null}
          </div>
          <BookDescription
            genre={book.genre.name}
            author={book.author.name}
            narrator={book?.narrator?.name}
            duration={book.fullDuration}
            annotation={book.annotation}
          />
        </div>
      </div>
    </>
  );
};

export default BookPage;
