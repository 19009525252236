import { memo } from 'react';

import css from './AudioList.module.css';

const AudioEqualizer = ({ isPlaying }) => {
  return (
    <div className={`${css.equalizer} ${isPlaying ? css.animate : ''}`}>
      <span className={`${css.bar} ${css.bar_1}`} />
      <span className={`${css.bar} ${css.bar_2}`} />
      <span className={`${css.bar} ${css.bar_3}`} />
    </div>
  );
};

export default memo(AudioEqualizer);
