import { useState, useEffect } from 'react';
import NarratorsService from '../services/NarratorsService';

//utils
import { resolveError } from '../../../../utils';

const useFetchNarrators = () => {
  const [narrators, setNarrators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchNarrators = async () => {
    setLoading(true);
    try {
      const { data } = await NarratorsService.getAll();

      setNarrators(data.data);
    } catch (error) {
      const serverErrors = resolveError(error.response);
      setError(serverErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNarrators();
  }, []);

  return { narrators, loading, error, fetchNarrators };
};

export default useFetchNarrators;
