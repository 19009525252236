//ui
import { Box, Tooltip, IconButton } from '@mui/material';
import { MaterialTable } from '../../../../components';
import { Delete, Edit } from '@mui/icons-material';

const columns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'name',
    header: 'Жанр',
  },
  {
    accessorKey: 'booksCount',
    header: 'Кількість книг',
  },
];

const GenresTable = ({ genres, loading, error, openDialog, removeGenre }) => {
  const handleOpenDialog = (genre) => () => openDialog(genre);

  const handleRemoveGenre = (id) => () => {
    const confirmed = window.confirm('Ви впевнені, що хочете видалити цей жанр?');

    if (confirmed) removeGenre(id);
  };

  return (
    <MaterialTable
      enableRowActions
      data={genres}
      columns={columns}
      muiToolbarAlertBannerProps={
        error
          ? {
              color: 'error',
              children: error,
            }
          : undefined
      }
      state={{ isLoading: loading, showAlertBanner: !!error }}
      renderRowActions={({ row }) => {
        const disabledRemoveBtn = row.original.booksCount > 0;

        return (
          <Box className="buttons-box">
            <Tooltip arrow placement="left" title="Редагувати">
              <IconButton onClick={handleOpenDialog(row.original)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Видалити">
              <span>
                <IconButton
                  color="error"
                  disabled={disabledRemoveBtn}
                  onClick={handleRemoveGenre(row.original.id)}>
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        );
      }}
    />
  );
};

export default GenresTable;
