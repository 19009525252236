import { useState, useEffect } from 'react';
import BooksService from '../services/BooksService';

//utils
import { resolveError } from '../../../../utils';

const useFetchBooks = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [isError, setError] = useState(null);

  const fetchBooks = async () => {
    setLoading(true);
    try {
      const { data } = await BooksService.getAll();
      setBooks(data.data);
    } catch (error) {
      const serverErrors = resolveError(error.response);
      setError(true);
      setMessage(serverErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
  }, []);

  return { books, loading, isError, message, fetchBooks };
};

export default useFetchBooks;
