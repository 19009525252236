//ui
import { Button, CircularProgress } from '@mui/material';
import css from './LoadingButton.module.css';

const LoadingButton = (props) => {
  const { name, loading, ...rest } = props;

  return (
    <Button {...rest}>
      <div className={css.box}>
        {name}
        {loading && <CircularProgress size={20} className={css.spinner} />}
      </div>
    </Button>
  );
};

export default LoadingButton;
