import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../../config/appRoutes';
import BooksService from '../services/BooksService';

//utils
import { resolveError } from '../../../../utils';
import { sortBookTracks } from '../utils';

const useBooksQueries = ({ image, tracks, fetchBooks }) => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const createBook = async ({ values }) => {
    setStatus('pending');
    try {
      const formData = new FormData();
      const bookTracks = sortBookTracks(tracks);

      formData.append('title', values.title);
      formData.append('authorId', values.author.id);
      formData.append('genreId', values.genre.id);
      if (values.narrator?.id) formData.append('narratorId', values.narrator.id);
      formData.append('annotation', values.annotation);
      formData.append('isPublished', Number(values.isPublished));
      formData.append('fileImage', image);
      bookTracks.forEach((track, index) => {
        formData.append(`tracks[${index}]`, track);
      });

      await BooksService.create({ payload: formData });
      setStatus('fulfilled');
      navigate(appRoutes.BOOKS.url);
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const updateBook = async ({ id, values }) => {
    setStatus('pending');
    try {
      const formData = new FormData();
      const bookTracks = sortBookTracks(tracks);

      formData.append('_method', 'put');
      formData.append('title', values.title);
      formData.append('authorId', values.author.id);
      formData.append('genreId', values.genre.id);
      if (values.narrator?.id) formData.append('narratorId', values.narrator.id);
      formData.append('annotation', values.annotation);
      formData.append('isPublished', Number(values.isPublished));

      if (image) formData.append('fileImage', image);

      bookTracks.forEach((track, index) => {
        formData.append(`tracks[${index}]`, track);
      });

      await BooksService.update({ id, payload: formData });
      setStatus('fulfilled');
      navigate(appRoutes.BOOKS.url);
    } catch (error) {
      const serverErrors = resolveError(error.response);

      setStatus('rejected');
      setMessage(serverErrors);
    }
  };

  const removeBook = async (id) => {
    await BooksService.remove(id);
    fetchBooks();
  };

  return { status, message, createBook, updateBook, removeBook };
};

export default useBooksQueries;
