import css from './ResetFiltersButton.module.css';

const ResetFiltersButton = ({ onFiltersReset }) => {
  return (
    <button className={css.clear_button} onClick={onFiltersReset}>
      Скинути фільтри
    </button>
  );
};

export default ResetFiltersButton;
