const appRoutes = {
  HOME: {
    url: '/',
    name: 'Головна',
  },
  ADMIN: {
    url: '/lb-admin',
    name: 'Форма авторизації',
  },
  ADMIN_PANEL: {
    url: '/dashboard',
    name: 'Адмін панель',
  },
  BOOKS: {
    url: '/dashboard/books',
    name: 'Книги',
  },
  BOOKS_CREATE: {
    url: '/dashboard/books/create',
    name: 'Створення книги',
  },
  BOOKS_EDIT: function (id) {
    return {
      url: id ? `/dashboard/books/edit/${id}` : '/dashboard/books/edit/:id',
      name: 'Редагування книги',
    };
  },
  GENRES: {
    url: '/dashboard/genres',
    name: 'Жанри',
  },
  AUTHORS: {
    url: '/dashboard/authors',
    name: 'Автори',
  },
  NARRATORS: {
    url: '/dashboard/narrators',
    name: 'Виконавці',
  },
};

export default appRoutes;
