import { Zoom } from '@mui/material';
import css from '../UploadImage.module.css';

const UploadImagePreview = ({ url }) => {
  return (
    <Zoom in={true}>
      <div className={css.image}>
        <img src={url} alt="preview" />
      </div>
    </Zoom>
  );
};

export default UploadImagePreview;
