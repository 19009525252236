import { FilterItem, ActiveFilter, ResetFiltersButton } from '../../components';
import { BlockSpinner } from '../../../../components';
import css from './Filters.module.css';

const Filters = (props) => {
  const {
    show,
    filtersData,
    selectedFilters,
    loading,
    onFilterRemove,
    onFiltersChange,
    onFiltersReset,
    setShow,
  } = props;
  const handleClose = () => setShow(false);
  const { filters, active } = filtersData;

  return (
    <aside className={`${css.filters} ${show ? css.show : '?'}`}>
      <header className={css.header}>
        <h3 className={css.title}>Фільтри</h3>
        <button aria-label="Згорнути" className={css.close_btn} onClick={handleClose} />
      </header>
      <div className={css.content}>
        {active.length > 0 ? (
          <div className={css.active_filters}>
            <div className={css.active_filters_title}>Ви обрали</div>
            <div className={css.active_filters_grid}>
              {active.map((filter) => (
                <ActiveFilter
                  key={filter.model + filter.id}
                  name={filter.name}
                  onClose={onFilterRemove(filter.model, filter.id)}
                />
              ))}

              <ResetFiltersButton onFiltersReset={onFiltersReset} />
            </div>
          </div>
        ) : null}
        <FilterItem
          showCount
          name="Жанр"
          list={filters.genres}
          selectedFilters={selectedFilters.genre}
          onFiltersChange={onFiltersChange('genre')}
        />
        <FilterItem
          showCount
          name="Автор"
          list={filters.authors}
          selectedFilters={selectedFilters.author}
          onFiltersChange={onFiltersChange('author')}
        />
        <FilterItem
          showCount
          name="Виконавець"
          list={filters.narrators}
          selectedFilters={selectedFilters.narrator}
          onFiltersChange={onFiltersChange('narrator')}
        />
      </div>
      <footer className={css.footer}>
        <button className={css.footer_btn} disabled={!active.length} onClick={onFiltersReset}>
          Очистити
        </button>
        <button
          className={`${css.footer_btn} ${css.blue_btn}`}
          disabled={!active.length}
          onClick={handleClose}>
          Показати
        </button>
      </footer>
      <BlockSpinner show={loading} />
    </aside>
  );
};

export default Filters;
