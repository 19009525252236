import { useState } from 'react';

const useUploadFiles = ({ files, setFiles, maxCount }) => {
  const [error, setError] = useState(null);

  const handleFilesChange = ({ target }) => {
    const uploadFiles = Array.from(target.files) ?? [];
    target.value = '';

    if (uploadFiles.length > maxCount) {
      setError(`Неможливо завантажити більше ${maxCount} файлів`);
    } else {
      setFiles([...files, ...uploadFiles]);
      setError(null);
    }
  };

  const removeFile = (index) => setFiles(files.filter((_, key) => key !== index));

  return { handleFilesChange, removeFile, error };
};

export default useUploadFiles;
