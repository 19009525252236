import axios from 'axios';

const customAxios = () => {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const instance = axios.create(defaultOptions);

  return instance;
};

export default customAxios();
