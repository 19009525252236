const convertBook = (book) => {
  return {
    ...book,
    genre: { id: book.genre.id, name: book.genre.name },
    author: { id: book.author.id, name: book.author.name },
    narrator: book?.narrator?.id ? { id: book.narrator.id, name: book.narrator.name } : null,
    isPublished: Boolean(book.isPublished),
  };
};

export default convertBook;
