const textCutting = (text, limit = 400) => {
  let t = text.trim();

  if (t.length <= limit) return t;

  t = t.slice(0, limit);
  const lastSpace = t.lastIndexOf(' ');

  if (lastSpace > 0) {
    t = t.substr(0, lastSpace);
  }

  return t + '...';
};

export default textCutting;
