//ui
import { Zoom, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

import css from '../UploadFiles.module.css';

const UploadFilesPreview = ({ file, onClose, index }) => {
  const handleClose = () => onClose(index);

  return (
    <Zoom in={true}>
      <div className={css.preview_wrap}>
        <div className={css.preview_box}>
          <AttachFileIcon className={css.icon} />
          <span className={css.name}>{file.name}</span>
        </div>
        <IconButton
          color="primary"
          aria-label="upload images"
          className={css.close}
          onClick={handleClose}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Zoom>
  );
};

export default UploadFilesPreview;
