const formatTime = (time) => {
  const roundedTime = Math.ceil(time);

  const hours = Math.floor(roundedTime / 3600)
    .toString()
    .padStart(2, '0');

  const minutes = Math.floor((roundedTime % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(roundedTime % 60)
    .toString()
    .padStart(2, '0');

  if (hours > 0) return `${hours}:${minutes}:${seconds}`;

  return `${minutes}:${seconds}`;
};

export default formatTime;
