import { Link } from 'react-router-dom';

//ui

import error404 from '../../assets/icons/error-404.svg';
import css from './NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <div className="container">
      <div className={css.not_found}>
        <div className={css.icon}>
          <img src={error404} alt="icon" />
          <h1>На жаль, запитувана вами сторінка не знайдена.</h1>
          <p>
            Перейдіть на <Link to="/">головну сторінку</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
