import { useState, useEffect } from 'react';

const useUploadImage = ({ file, setFile }) => {
  const [dragActive, setDragActive] = useState(false);

  const handleFileChange = ({ target }) => {
    const currentFile = target.files ? target.files[0] : null;

    if (currentFile) setImage(currentFile);

    target.value = '';
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    const { type } = e;

    if (type === 'dragenter' || type === 'dragover') {
      setDragActive(true);
    } else if (type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const currentFile = e.dataTransfer.files[0];
      const isImage = e.dataTransfer.files[0].type.includes('image');

      if (isImage) setImage(currentFile);
    }
  };

  const setImage = (file) => {
    setFile(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  };

  useEffect(
    () => () => {
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    },
    [file]
  );

  return { dragActive, handleFileChange, handleDrag, handleDrop };
};

export default useUploadImage;
