import { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const DURATION = 5000;

const CustomSnackbar = ({ text, type }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={DURATION}>
      <Alert severity={type ?? 'error'} variant="filled">
        {text}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
