//ui
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import css from './LegalModal.module.css';

const LegalModal = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  const isFullScreen = useMediaQuery('(max-width:414px)');

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={open}
      maxWidth="md"
      onClose={handleClose}
      PaperProps={{ classes: { root: css.dialog } }}>
      <DialogTitle className={css.title}>
        Публічний договір (оферта) доступу до «Бібліотека АЛЛО»
        <button aria-label="Згорнути" className={css.close_btn} onClick={handleClose} />
      </DialogTitle>
      <DialogContent className={css.content}>
        <p>
          Товариство з обмеженою відповідальністю «АЛЛО» (код ЄДРПОУ 30012848, місцезнаходження:
          49044, м. Дніпро, вул. Барикадна, 15-А) з однієї сторони, що в подальшому за текстом
          іменується «Адміністратор», а також особа, яка приймає умови цього Договору відповідно до
          визначених в ньому правил (надалі – «Користувач»), з іншої сторони, що надалі іменуються,
          як «Сторони», або окремо – «Сторона», уклали цей Договір про наступне.
        </p>
        <ol className={css.parent}>
          <li className={css.item}>
            <b>Терміни.</b>
            <ol className={css.child}>
              <li>
                Бібліотека АЛЛО - онлайн-бібліотека із аудіо- та/чи електронними книгами, що
                розміщена за посиланням library.allo.ua. Доступ до цієї бібліотеки надається особам,
                що мають Код доступу та протягом Строку дії такого Коду. Наповнення Бібліотеки АЛЛО
                може змінюватись Адміністратором на власний розсуд без попереднього погодження та/чи
                інформування Користувачів. Мінімальна гарантована кількість книг у кількості одна
                штука у доступі.
              </li>
              <li>
                Код доступу - код за допомогою якого Користувач має доступ до Бібліотеки АЛЛО
                протягом строку дії такого Коду доступу. Строк доступу обчислюється з дати продажу
                Коду доступу.
              </li>
              <li>
                Твори (контент) - тексти або аудіозаписи (фонограми) літературних творів (включаючи
                обкладинки, ілюстрації тощо), розміщені в електронному вигляді в мережі Інтернет в
                різних форматах на сайті Адміністратора library.allo.ua.{' '}
              </li>
            </ol>
          </li>
          <li className={css.item}>
            <b>Загальні положення.</b>

            <ol className={css.child}>
              <li>
                Цей Публічний договір регламентує порядок надання Адміністратором доступу
                Користувача до Творів та регулює взаємодію між Адміністратором та Користувачем. Цей
                договір є Публічним договором в розумінні ст. 633 Цивільного кодексу України і має
                відповідну юридичну силу.
              </li>
              <li>
                Договір вважається погодженим та укладеним Користувачем шляхом вчинення Користувачем
                дій, що свідчать про згоду дотримуватися умов Договору, без підписання письмового
                примірника Сторонами. Відповідно до положень ст. 642 Цивільного кодексу України
                повним і безумовним акцептом (прийняттям) Користувачем умов цього Публічного
                договору є підтвердження Користувачем своєї згоди з умовами Публічного договору
                шляхом: або здійснення ним придбання Коду доступу до Бібліотеки АЛЛО, або
                авторизації на сайті Бібліотеки АЛЛО.
              </li>
              <li>
                Договір є публічним і діє протягом строку дії Коду доступу до його припинення
                будь-якою зі Сторін у порядку, встановленому цим Договором.
              </li>
              <li>Договір є публічним і діє протягом строку дії Коду доступу.</li>
              <li>
                Умови Договору можуть бути змінені Адміністратором та розміщені в оновленій
                редакції. Користувач самостійно несе обов'язок щодо ознайомлення із новими
                редакціями Договору. У разі незгоди Користувача зі змінами, внесеними до Договору,
                такий Користувач має право припинити користування Бібліотекою АЛЛО, що вважатиметься
                розірванням публічного договору.{' '}
              </li>
            </ol>
          </li>
          <li className={css.item}>
            <b>Предмет договору.</b>
            <ol className={css.child}>
              <li>
                Адміністратор надає доступ до Творів (контенту), а Користувач має право використати
                для особистих цілей (прочитати або прослухати) такі Твори (контент) із дотриманням
                цього Договору та законодавства щодо авторських та суміжних прав. Для цього
                Користувач протягом строку дії Коду доступу авторизується на сайті library.allo.ua.
                Адміністратор зі своєї Сторони зобов’язується надавати такий доступ протягом строку
                дії Коду доступу Користувача.
              </li>
            </ol>
          </li>
          <li className={css.item}>
            <b>Права та обов’язки.</b>
            <ol className={css.child}>
              <li>
                Користувачу заборонено пропонувати або використовувати інформацію, розміщену на
                сайті library.allo.ua, в тому числі Твори (контент), з комерційною метою без
                письмової згоди її авторів/правовласників і Адміністратора, а також будь-яким іншим
                чином поширювати Твори та/або його копії.
              </li>
              <li>
                У разі виявлення фактів передачі даних Коду доступу Користувачем третім особам або
                фактів колективного користування Кодом доступу до Бібліотеки АЛЛО Адміністрація має
                право обмежити чи скасувати доступ до Бібліотеки АЛЛО за таким Кодом доступу.
              </li>
              <li>
                Користувач погоджується та гарантує не робити будь-яких дій, що можуть розглядатися
                як порушення законодавства або норм міжнародного права, в тому числі в сфері
                інтелектуальної власності, авторських і/або суміжних правах, а також будь-яких дій,
                які призводять або можуть призвести до порушення нормального функціонування
                Бібліотеки АЛЛО.
              </li>
              <li>
                Адміністратор зберігає за собою право без попередньої згоди змінювати Твори
                (контент) у Бібліотеці АЛЛО, додавати нові аудіо- та/чи електронні книги, видаляти
                Твори (контент). Адміністратор може проінформувати Користувачів про зміни на сайті
                Бібліотеки АЛЛО, але наявність такого попередження не є обов’язковою. Приєднуючись
                до цього Договору, Користувач підтверджує свою згоду із цим.{' '}
              </li>
            </ol>
          </li>
          <li className={css.item}>
            <b>Інше.</b>
            <ol className={css.child}>
              <li>
                Сторони несуть відповідальність за порушення, невиконання та/або неналежне виконання
                цього Договору відповідно до його умов та згідно з чинним законодавством України.
              </li>
              <li>
                Приймаючи умови Договору Користувач підтверджує, що ознайомлений і згоден із умовами
                цього Договору та погоджується із ними.
              </li>
              <li>
                Адміністратор не несе відповідальності за невиконання або неналежне виконання
                зобов'язань передбачених цим Договором, а також за шкоду, завдану у зв'язку з цим,
                внаслідок, але не обмежуючись: неправомірними діями третіх осіб; перебоями в роботі
                сайту Бібліотеки АЛЛО, зумовленими помилками в коді, комп'ютерними вірусами та
                іншими сторонніми фрагментами коду в програмному забезпеченні сайту; відсутністю
                інтернет-з'єднань між пристроєм Користувача і сервером сайту, а також між сервером
                сайту і мережею Інтернет; проведенням державними органами та іншими належним чином
                уповноваженими організаціями оперативних заходів, що впливають на роботу сайту;
                виконанням оновлень програмного забезпечення сайту; за застосовним і таким, що набув
                чинності, рішенням уповноважених органів державної влади.
              </li>
              <li>
                Відповідальність Адміністратора не може перевищувати вартості Коду доступу та не
                включає будь-яку упущену вигоду, непрямі збитки, шкоду завдану третім особам.
              </li>
              <li>
                Якщо умовами даного Договору питання не регулюється, Сторони можуть керуватися
                положеннями Угоди користувача (Умови використання сайту) та/або чинним
                законодавством України.{' '}
              </li>
            </ol>
          </li>
        </ol>
      </DialogContent>
    </Dialog>
  );
};

export default LegalModal;
