import { useRouteError } from 'react-router-dom';
import { ErrorMessage } from '../../components';

const ErrorBoundary = () => {
  const error = useRouteError();

  console.error(error);

  return <ErrorMessage error="Виникла помилка при завантаженні компонента" />;
};

export default ErrorBoundary;
