import { combineReducers } from '@reduxjs/toolkit';

//slices
import { authReducer } from '../modules/Authentication';
import { authClientReducer } from '../modules/Authentication';

export const rootReducer = combineReducers({
  auth: authReducer,
  authClient: authClientReducer,
});
