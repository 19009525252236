import { memo } from 'react';
import css from './FilterItem.module.css';

const FilterItemLabel = ({ label, showCount, count }) => {
  return (
    <span className={css.text}>
      {label} {showCount ? <i>({count})</i> : null}
    </span>
  );
};

export default memo(FilterItemLabel);
