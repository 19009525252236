//ui
import { CircularProgress } from '@mui/material';
import css from './BlockSpinner.module.css';

const BlockSpinner = ({ show }) => {
  return (
    <div className={`${css.box} ${show ? css.show : ''}`}>
      <CircularProgress />
    </div>
  );
};

export default BlockSpinner;
