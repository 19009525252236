import { useState, useEffect, useCallback } from 'react';
import BookCatalogService from '../services/BookCatalogService';

const useFetchFilters = (selected) => {
  const [data, setData] = useState({ filters: {}, active: [] });
  const [loadingFilters, setLoadingFilters] = useState(true);
  const { genre, narrator, author } = selected;

  const fetchFilters = useCallback(async ({ genre, author, narrator }) => {
    setLoadingFilters(true);

    try {
      const { data } = await BookCatalogService.getFilters({ genre, author, narrator });

      setData({ filters: data.data, active: data.active });
    } finally {
      setLoadingFilters(false);
    }
  }, []);

  useEffect(() => {
    fetchFilters({ genre, narrator, author });
  }, [genre, narrator, author, fetchFilters]);

  return { data, loadingFilters };
};

export default useFetchFilters;
