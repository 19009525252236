import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../modules/Authentication/slices/auth';
import { fetchUser } from '../../modules/Authentication/slices/auth/authActions';

//ui
import { useMediaQuery, useTheme } from '@mui/material';
import { Header, Sidebar } from './components';
import css from './DashboardLayout.module.css';

const DashboardLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const variant = isDesktop ? 'persistent' : 'temporary';
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const handleSidebarOpen = () => setOpenSidebar(true);
  const handleSidebarClose = () => setOpenSidebar(false);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser({ navigate }));
    }
  }, [user, dispatch, navigate]);

  return (
    <div className={`${css.wrap} ${isDesktop ? css.content_shift : ''}`}>
      <Header onSidebarOpen={handleSidebarOpen} />
      <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={variant} />
      <main className={css.content}>
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
