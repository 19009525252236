//ui
import SidebarProfile from './SidebarProfile';
import SidebarNavigation from './SidebarNavigation';
import { Drawer, Divider } from '@mui/material';
import css from './Sidebar.module.css';

const Sidebar = ({ open, variant, onClose }) => {
  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      PaperProps={{ classes: { root: css.drawer } }}>
      <div className={css.sidebar}>
        <SidebarProfile />
        <Divider className={css.divider} />
        <SidebarNavigation />
      </div>
    </Drawer>
  );
};

export default Sidebar;
