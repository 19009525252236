import { MRT_Localization_UK } from 'material-react-table/locales/uk';

//ui
import { MaterialReactTable } from 'material-react-table';

const MaterialTable = (props) => {
  return (
    <MaterialReactTable
      {...props}
      localization={MRT_Localization_UK}
      initialState={{ pagination: { pageSize: 15, pageIndex: 0 } }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [15, 30, 50],
      }}
    />
  );
};

export default MaterialTable;
