import AddIcon from '@mui/icons-material/Add';
import css from '../UploadImage.module.css';

const UploadImageButton = () => {
  return (
    <>
      <div className={css.icon}>
        <AddIcon />
      </div>
      <span>Додати</span>
    </>
  );
};

export default UploadImageButton;
