import { useState } from 'react';

//ui
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import { BlockSpinner } from '../../../../components';

const NarratorsDialog = (props) => {
  const { narrator, open, status, setOpen, createNarrator, updateNarrator } = props;
  const [name, setName] = useState(narrator?.name ?? '');
  const title = narrator?.id ? 'Оновити дані виконавця' : 'Додати виконавця';

  const handleClose = () => setOpen(false);

  const handleChange = ({ target }) => {
    const value = target.value;

    setName(value);
  };

  const handleSubmit = async () => {
    if (narrator?.id) {
      const { id } = narrator;

      await updateNarrator({ id, name });
    } else {
      await createNarrator(name);
    }

    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            minWidth: { md: '400px' },
            padding: '10px 0',
          }}>
          <TextField
            fullWidth
            autoFocus
            color="secondary"
            size="small"
            variant="outlined"
            label="Виконавець"
            value={name}
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Відміна
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          Зберегти
        </Button>
      </DialogActions>
      <BlockSpinner show={status === 'pending'} />
    </Dialog>
  );
};

export default NarratorsDialog;
