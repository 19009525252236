import { useState } from 'react';

//ui
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import { BlockSpinner } from '../../../../components';

const GenresDialog = (props) => {
  const { genre, open, status, setOpen, createGenre, updateGenre } = props;
  const [name, setName] = useState(genre?.name ?? '');
  const title = genre?.id ? 'Оновити дані жанра' : 'Додати жанр';

  const handleClose = () => setOpen(false);

  const handleChange = ({ target }) => {
    const value = target.value;

    setName(value);
  };

  const handleSubmit = async () => {
    if (genre?.id) {
      await updateGenre({ id: genre.id, name });
    } else {
      await createGenre(name);
    }

    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            minWidth: { md: '400px' },
            padding: '10px 0',
          }}>
          <TextField
            fullWidth
            autoFocus
            color="secondary"
            size="small"
            variant="outlined"
            label="Жанр"
            value={name}
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Відміна
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          Зберегти
        </Button>
      </DialogActions>
      <BlockSpinner show={status === 'pending'} />
    </Dialog>
  );
};

export default GenresDialog;
