import { formatTime } from '../utils';
import css from './AudioList.module.css';

const AudioTimeDisplay = ({ currentTime, duration, isUpdateTime }) => {
  return (
    <span className={css.text}>
      {isUpdateTime ? formatTime(currentTime) : '00:00'} / {formatTime(duration)}
    </span>
  );
};

export default AudioTimeDisplay;
