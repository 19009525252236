import { createSlice } from '@reduxjs/toolkit';
import { login, logout, authCheckState } from './authClientActions';

const initialState = {
  voucherExpiration: null,
  values: { code: '', remember: false },
  isAuth: false,
  show: false,
  loading: false,
  error: null,
};

export const authClientSlice = createSlice({
  name: 'authClient',
  initialState,
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setValues: (state, action) => {
      const { name, value } = action.payload;

      state.values = { ...state.values, [name]: value };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.voucherExpiration = payload;
      state.show = false;
      state.isAuth = true;
      state.values = { code: '', remember: false };
    });

    builder.addCase(login.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.isAuth = false;
      state.voucherExpiration = null;
    });

    builder.addCase(authCheckState.fulfilled, (state, { payload }) => {
      state.isAuth = payload?.isAuth ?? false;
      state.voucherExpiration = payload?.voucherExpiration ?? null;
    });
  },
});

export const { reducer: authClientReducer, actions: authClientActions } = authClientSlice;
