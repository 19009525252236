import { createAsyncThunk } from '@reduxjs/toolkit';
import apiRoutes from '../../../../config/apiRoutes';
import appRoutes from '../../../../config/appRoutes';

//utils
import { customAxios, resolveError } from '../../../../utils';

export const login = createAsyncThunk(
  'auth/login',
  async ({ login, password, navigate }, { rejectWithValue }) => {
    try {
      await customAxios.get(apiRoutes.SANCTUM);
      await customAxios.post(apiRoutes.LOGIN, { login, password });
      const { data } = await customAxios.get(apiRoutes.USER);
      localStorage.setItem('user', JSON.stringify(data));

      navigate(appRoutes.BOOKS.url);

      return data;
    } catch (error) {
      const serverErrors = resolveError(error.response);

      return rejectWithValue(serverErrors);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  try {
    await customAxios.post(apiRoutes.LOGOUT, {});
    localStorage.removeItem('user');
  } catch (error) {
    console.log(resolveError(error.response));
  }
});

export const fetchUser = createAsyncThunk(
  'auth/user',
  async ({ navigate }, { rejectWithValue }) => {
    try {
      const { data } = await customAxios.get(apiRoutes.USER);

      return data;
    } catch (error) {
      const serverErrors = resolveError(error.response);
      localStorage.removeItem('user');
      navigate(appRoutes.ADMIN.url);

      return rejectWithValue(serverErrors);
    }
  }
);
