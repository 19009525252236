import { useNavigate, useLocation } from 'react-router-dom';
import appRoutes from '../../../../config/appRoutes';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { authClientActions, logout } from '../../../../modules/Authentication';

//ui
import { Button, useMediaQuery } from '@mui/material';
import HeaderVoucherExpiration from './HeaderVoucherExpiration';
//import HeaderBookListeners from './HeaderBookListeners';
import CompanyLogoIcon from './icons/CompanyLogoIcon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import css from './Header.module.css';

const Header = ({ onOpenLegal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useSelector((state) => state.authClient);
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery('(max-width:768px)');
  const buttonNotAuthText = isMobileScreen ? 'Активація' : 'Активація коду';

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      if (location.pathname !== appRoutes.HOME.url) navigate(appRoutes.HOME.url);
    });
  };

  const handleOpen = (e) => {
    if (client.isAuth) {
      handleLogout();
    } else {
      dispatch(authClientActions.setShow(true));
    }
  };

  return (
    <header className={css.header}>
      <div className="container">
        <div className={css.box}>
          <div className={css.logo_box}>
            <a href="/" className={css.logo}>
              <CompanyLogoIcon />
            </a>
            {/* <HeaderBookListeners /> */}
          </div>

          <div className={css.wrap}>
            {client.isAuth ? (
              <HeaderVoucherExpiration voucherExpiration={client?.voucherExpiration} />
            ) : null}
            <button className={css.btn} onClick={onOpenLegal}>
              <HelpOutlineIcon className={css.icon} />
            </button>
            <Button
              color="primary"
              variant="contained"
              className={css.mui_btn}
              onClick={handleOpen}>
              {client.isAuth ? 'Вихід' : buttonNotAuthText}
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
