import { createSlice } from '@reduxjs/toolkit';
import { login, fetchUser } from './authActions';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    });

    builder.addCase(login.rejected, (state, { payload }) => {
      state.loading = false;

      state.error = payload;
    });

    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    });

    builder.addCase(fetchUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.user = null;
      state.error = payload;
    });
  },
});

export const selectAuth = (state) => state.auth;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
