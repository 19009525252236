import { useState } from 'react';
import appRoutes from '../../../config/appRoutes';

//hooks
import { useFetchNarrators, useNarratorsQueries } from './hooks';

//ui
import { PageTitle, CustomSnackbar } from '../../../components';
import { NarratorsTable, NarratorsDialog } from './components';
import { Button } from '@mui/material';

const NarratorsPage = () => {
  const [open, setOpen] = useState(false);
  const [narrator, setNarrator] = useState(null);
  const { narrators, loading, error, fetchNarrators } = useFetchNarrators();
  const { createNarrator, updateNarrator, removeNarrator, status, message } =
    useNarratorsQueries(fetchNarrators);

  const handleOpenDialog = (narrator) => {
    setOpen(true);

    if (narrator?.id) {
      const { id, name } = narrator;
      setNarrator({ id, name });
    } else {
      setNarrator(null);
    }
  };

  return (
    <>
      <PageTitle title={appRoutes.NARRATORS.name} />
      <Button
        variant="contained"
        color="success"
        className="button-indents"
        onClick={handleOpenDialog}>
        Додати
      </Button>

      <NarratorsTable
        narrators={narrators}
        loading={loading}
        error={error}
        removeNarrator={removeNarrator}
        openDialog={handleOpenDialog}
      />

      {open ? (
        <NarratorsDialog
          open={open}
          setOpen={setOpen}
          narrator={narrator}
          createNarrator={createNarrator}
          updateNarrator={updateNarrator}
          status={status}
        />
      ) : null}

      {status === 'rejected' ? <CustomSnackbar text={message} /> : null}
    </>
  );
};

export default NarratorsPage;
