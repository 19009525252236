import { useMemo, memo } from 'react';

//icons
import DoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import DoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeDownOutlinedIcon from '@mui/icons-material/VolumeDownOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';

import css from './Controls.module.css';

const VOLUME_DOWN = 40;
const VOLUME_OFF = 1;
const INITIAL_SPEED = 0;

const Controls = (props) => {
  const {
    isPlaying,
    volume,
    speed,
    handlePlayPause,
    handleVolumeChange,
    handleSpeedChange,
    handleSkipForward,
    handleSkipBackward,
  } = props;

  const speedProgress = useMemo(() => {
    const startSpeed = 50;

    return startSpeed + Number(speed) / 2;
  }, [speed]);

  return (
    <div className={css.box}>
      <div className={css.title}>Програвач</div>
      <div className={css.controls}>
        <div className={css.wrap}>
          <button className={`${css.btn} ${css.back_text}`} onClick={handleSkipBackward}>
            <DoubleArrowLeftRoundedIcon /> 15 c
          </button>
          <button className={`${css.btn} ${css.play}`} onClick={handlePlayPause}>
            {isPlaying ? (
              <PauseRoundedIcon color="secondary" />
            ) : (
              <PlayArrowRoundedIcon className={css.play_icon} />
            )}
          </button>
          <button className={`${css.btn} ${css.forward_text}`} onClick={handleSkipForward}>
            15 c <DoubleArrowRightRoundedIcon />
          </button>
        </div>
        <div className={css.wrap}>
          <div className={css.slider} tabIndex="0">
            <div className={css.speed_value}>
              {speed === INITIAL_SPEED
                ? 'Швидкість'
                : `${Number(speed) > INITIAL_SPEED ? '+' + speed : speed}%`}
            </div>
            <div className={css.progress} style={{ width: `${speedProgress}%` }} />
            <input
              tabIndex="-1"
              type="range"
              step={5}
              min={-100}
              max={100}
              className={css.hidden_slider}
              value={speed}
              onChange={handleSpeedChange}
            />
          </div>

          <div className={css.slider} tabIndex="0">
            {volume < VOLUME_OFF ? (
              <VolumeOffOutlinedIcon />
            ) : volume < VOLUME_DOWN ? (
              <VolumeDownOutlinedIcon />
            ) : (
              <VolumeUpOutlinedIcon />
            )}

            <div className={css.volume}>{volume}%</div>
            <div className={css.progress} style={{ width: `${volume}%` }} />
            <input
              tabIndex="-1"
              type="range"
              step={1}
              className={css.hidden_slider}
              value={volume}
              onChange={handleVolumeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Controls);
