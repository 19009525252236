import { useState, useEffect } from 'react';
import GenresService from '../services/GenresService';

//utils
import { resolveError } from '../../../../utils';

const useFetchGenres = () => {
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGenres = async () => {
    setLoading(true);
    try {
      const { data } = await GenresService.getAll();

      setGenres(data.data);
    } catch (error) {
      const serverErrors = resolveError(error.response);
      setError(serverErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGenres();
  }, []);

  return { genres, error, loading, fetchGenres };
};

export default useFetchGenres;
