import { useState } from 'react';

//ui
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import { BlockSpinner } from '../../../../components';

const AuthorsDialogs = (props) => {
  const { author, open, status, setOpen, createAuthor, updateAuthor } = props;
  const [name, setName] = useState(author?.name ?? '');
  const title = author?.id ? 'Оновити дані автора' : 'Додати автора';

  const handleClose = () => setOpen(false);

  const handleChange = ({ target }) => {
    const value = target.value;

    setName(value);
  };

  const handleSubmit = async () => {
    if (author?.id) {
      await updateAuthor({ id: author.id, name });
    } else {
      await createAuthor(name);
    }

    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            minWidth: { md: '400px' },
            padding: '10px 0',
          }}>
          <TextField
            fullWidth
            autoFocus
            color="secondary"
            size="small"
            variant="outlined"
            label="Автор"
            value={name}
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Відміна
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          Зберегти
        </Button>
      </DialogActions>
      <BlockSpinner show={status === 'pending'} />
    </Dialog>
  );
};

export default AuthorsDialogs;
