import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../slices/auth';
import { login as handleLogin } from '../slices/auth/authActions';

//ui
import { Paper, Typography, TextField } from '@mui/material';
import { LoadingButton } from '../../../components';

import css from './LoginForm.module.css';

const LoginForm = () => {
  const { error, loading } = useSelector(selectAuth);
  const [values, setValues] = useState({ login: '', password: '' });
  const navigate = useNavigate();
  const { login, password } = values;
  const isDisableButton = login === '' || password === '';
  const dispatch = useDispatch();

  const handleChangeValues = ({ target }) => {
    const { name, value } = target;

    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (loading) return;

    dispatch(handleLogin({ login, password, navigate }));
  };

  return (
    <div className={css.container}>
      <Paper elevation={3} className={css.paper}>
        <Typography className={css.title} variant="h2">
          Авторизація
        </Typography>
        <form onSubmit={handleSubmitForm}>
          <TextField
            fullWidth
            color="secondary"
            className={css.text_field}
            label="Логін"
            name="login"
            type="text"
            variant="outlined"
            value={login}
            onChange={handleChangeValues}
          />
          <TextField
            fullWidth
            color="secondary"
            className={css.text_field}
            label="Пароль"
            name="password"
            type="password"
            autoComplete="on"
            variant="outlined"
            value={password}
            onChange={handleChangeValues}
          />
          <div className={css.error}>{error}</div>
          <LoadingButton
            fullWidth
            disabled={isDisableButton}
            loading={loading}
            name="Увійти"
            size="large"
            className={css.button}
            color="primary"
            type="submit"
            variant="contained"
          />
        </form>
      </Paper>
    </div>
  );
};

export default LoginForm;
