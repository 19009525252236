//hooks
import useAudioPlayer from './hooks/useAudioPlayer';

import { AudioList, Controls } from './components';
import css from './AudioPlayer.module.css';

const AudioPlayer = ({ audioList }) => {
  const {
    isPlaying,
    currentTime,
    duration,
    speed,
    volume,
    currentTrackIndex,
    handlePlayPause,
    handleSpeedChange,
    handleVolumeChange,
    handleSkipForward,
    handleSkipBackward,
    handleSeek,
    handleTrackChange,
    audioRef,
  } = useAudioPlayer(audioList);

  return (
    <>
      <div className={css.audio_player}>
        <Controls
          isPlaying={isPlaying}
          speed={speed}
          volume={volume}
          handleSpeedChange={handleSpeedChange}
          handleVolumeChange={handleVolumeChange}
          handleSkipForward={handleSkipForward}
          handleSkipBackward={handleSkipBackward}
          handlePlayPause={handlePlayPause}
        />
      </div>

      <AudioList
        isPlaying={isPlaying}
        audioList={audioList}
        currentTrackIndex={currentTrackIndex}
        duration={duration}
        currentTime={currentTime}
        handleSeek={handleSeek}
        handleTrackChange={handleTrackChange}
      />
      <audio ref={audioRef} />
    </>
  );
};

export default AudioPlayer;
