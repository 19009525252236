import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const INITIAL_VOLUME = 50;

const useAudioPlayer = (audioList) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [volume, setVolume] = useState(INITIAL_VOLUME);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const audioRef = useRef();
  const currentTrack = audioList[currentTrackIndex];
  const { name } = useParams();

  const setPlaybackRate = useCallback((speed) => {
    const initialSpeed = 1;
    const minPlayBackRate = 0.0625;
    const playbackRate = initialSpeed + speed / 100;

    if (playbackRate > minPlayBackRate) {
      audioRef.current.playbackRate = playbackRate;
    } else {
      audioRef.current.playbackRate = minPlayBackRate;
    }
  }, []);

  const handlePlayPause = useCallback(() => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const handleTimeUpdate = useCallback(() => {
    const audio = audioRef.current;

    setCurrentTime(audio.currentTime);
  }, []);

  const handleLoadedMetadata = useCallback(() => {
    const audio = audioRef.current;

    setDuration(audio.duration);
  }, []);

  const handleSpeedChange = useCallback(
    (e) => {
      const newSpeed = parseFloat(e.target.value);

      setSpeed(newSpeed);
      setPlaybackRate(newSpeed);
    },
    [setPlaybackRate]
  );

  const handleVolumeChange = useCallback((e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);

    audioRef.current.volume = newVolume / 100;
  }, []);

  const handleSeek = useCallback((newTime) => {
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  }, []);

  const handleSkipForward = useCallback(() => {
    audioRef.current.currentTime += 15;
  }, []);

  const handleSkipBackward = useCallback(() => {
    audioRef.current.currentTime -= 15;
  }, []);

  const handleTrackChange = useCallback((index, currentTrackIndex) => {
    if (index === currentTrackIndex) return;

    const audio = audioRef.current;

    setCurrentTrackIndex(index);
    audio.autoplay = true;
    setIsPlaying(true);
  }, []);

  const handleEnded = useCallback(() => {
    const audio = audioRef.current;

    if (currentTrackIndex < audioList.length - 1) {
      setCurrentTrackIndex((prevIndex) => prevIndex + 1);
      audio.autoplay = true;
    } else {
      setIsPlaying(false);
    }
  }, [currentTrackIndex, audioList.length]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.src = currentTrack.track;

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [currentTrack, handleTimeUpdate, handleLoadedMetadata, handleEnded]);

  useEffect(() => {
    const playerData = localStorage.getItem(name);

    if (playerData) {
      const { currentTrackIndex, speed, volume, currentTime } = JSON.parse(playerData);

      setCurrentTrackIndex(currentTrackIndex);
      setSpeed(speed);
      setVolume(volume);
      setCurrentTime(currentTime);

      audioRef.current.currentTime = currentTime;
      audioRef.current.volume = volume / 100;
      setPlaybackRate(speed);
    }
  }, [name, setPlaybackRate]);

  useEffect(() => {
    const audioPlayerData = {
      currentTrackIndex,
      speed,
      volume,
      currentTime,
    };

    localStorage.setItem(name, JSON.stringify(audioPlayerData));
  }, [currentTrackIndex, speed, volume, currentTime, name]);

  return {
    isPlaying,
    currentTime,
    duration,
    speed,
    volume,
    currentTrackIndex,
    handlePlayPause,
    handleSpeedChange,
    handleVolumeChange,
    handleSkipForward,
    handleSkipBackward,
    handleSeek,
    handleTrackChange,
    audioRef,
  };
};

export default useAudioPlayer;
