import { Navigate, useLocation } from 'react-router-dom';
import appRoutes from '../../config/appRoutes';

//modules
import { LoginForm } from '../../modules/Authentication';

const LoginPage = () => {
  const user = localStorage.getItem('user');
  const location = useLocation();

  return user ? (
    <Navigate to={appRoutes.BOOKS.url} state={{ from: location }} replace />
  ) : (
    <LoginForm />
  );
};

export default LoginPage;
