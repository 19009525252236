import { useState } from 'react';
import { format, fromUnixTime } from 'date-fns';

//ui
import { useMediaQuery, Popover } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import css from './Header.module.css';

const HeaderVoucherExpiration = ({ voucherExpiration }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const expirationDate = voucherExpiration
    ? format(fromUnixTime(voucherExpiration), 'dd.MM.yyyy')
    : '-';
  const isMobileScreen = useMediaQuery('(max-width:768px)');
  const open = Boolean(anchorEl);
  const id = open ? 'date-popover' : undefined;

  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return isMobileScreen ? (
    <>
      <button className={css.btn} onClick={handleOpen}>
        <AccessTimeIcon className={css.icon} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <div className={css.popover_expiration_date}>
          Дійсний до: <strong>{expirationDate}</strong>
        </div>
      </Popover>
    </>
  ) : (
    <div className={css.expiration_date}>
      Дійсний до: <strong>{expirationDate}</strong>
    </div>
  );
};

export default HeaderVoucherExpiration;
