import apiRoutes from '../../../../config/apiRoutes';
import { customAxios } from '../../../../utils';

class BooksService {
  static getAll() {
    return customAxios.get(apiRoutes.AUDIO_BOOKS);
  }

  static getById(id) {
    return customAxios.get(apiRoutes.AUDIO_BOOKS + '/' + id);
  }

  static create({ payload }) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return customAxios.post(apiRoutes.AUDIO_BOOKS, payload, config);
  }

  static update({ id, payload }) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return customAxios.post(apiRoutes.AUDIO_BOOKS + '/' + id, payload, config);
  }

  static remove(id) {
    return customAxios.delete(apiRoutes.AUDIO_BOOKS + '/' + id);
  }

  static getAuthors() {
    return customAxios.get(apiRoutes.AUTHORS);
  }

  static getGenres() {
    return customAxios.get(apiRoutes.GENRES);
  }

  static getNarrators() {
    return customAxios.get(apiRoutes.NARRATORS);
  }
}

export default BooksService;
