import appRoutes from './config/appRoutes';
import { ProtectedRoute, ProtectedLoginRoute, ErrorBoundary } from './components';

//layouts
import { MainLayout } from './layouts/MainLayout';
import { DashboardLayout } from './layouts/DashboardLayout';

//pages
import {
  BookPage,
  BookCatalogPage,
  LoginPage,
  BooksPage,
  CreateBookPage,
  EditBookPage,
  GenresPage,
  AuthorsPage,
  NarratorsPage,
  NotFoundPage,
} from './pages';

export const routes = [
  {
    path: appRoutes.HOME.url,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <BookCatalogPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/books/:name',
        element: <BookPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.ADMIN.url,
        element: (
          <ProtectedLoginRoute>
            <LoginPage />
          </ProtectedLoginRoute>
        ),
        errorElement: <ErrorBoundary />,
      },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: appRoutes.BOOKS.url,
        element: <BooksPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.BOOKS_CREATE.url,
        element: <CreateBookPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.BOOKS_EDIT().url,
        element: <EditBookPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.GENRES.url,
        element: <GenresPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.AUTHORS.url,
        element: <AuthorsPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: appRoutes.NARRATORS.url,
        element: <NarratorsPage />,
        errorElement: <ErrorBoundary />,
      },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
];
