import { useState } from 'react';

//ui
import FilterItemLabel from './FilterItemLabel';
import FilterItemAccordionSummary from './FilterItemAccordionSummary';
import { Accordion, AccordionDetails, FormControlLabel, Checkbox } from '@mui/material';

import css from './FilterItem.module.css';

const FilterItem = ({ showCount, name, list, selectedFilters, onFiltersChange }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  if (list?.length === 0) return null;

  return (
    <Accordion disableGutters expanded={expanded} onChange={handleChange} className={css.filter}>
      <FilterItemAccordionSummary className={css.title}>
        <span className={css.title}>{name}</span>
      </FilterItemAccordionSummary>
      <AccordionDetails className={css.details}>
        {list?.map((item) => (
          <FormControlLabel
            key={item.id}
            className={css.label}
            value={item.id}
            control={<Checkbox classes={{ root: css.checkbox }} color="secondary" />}
            label={
              <FilterItemLabel showCount={showCount} label={item.name} count={item.books_count} />
            }
            checked={selectedFilters?.includes(String(item.id))}
            onChange={onFiltersChange}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterItem;
