import apiRoutes from '../../../config/apiRoutes';
import { customAxios } from '../../../utils';

class BookService {
  static getByName(name) {
    return customAxios.get(apiRoutes.BOOKS(name));
  }
}

export default BookService;
