//hooks
import useUploadFiles from './hooks/useUploadFiles';

//ui
import UploadFilesPreview from './components/UploadFilesPreview';
import AttachedFilesPreview from './components/AttachedFilesPreview';
import { Button } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import css from './UploadFiles.module.css';

const MAX_COUNT = 20;

const UploadFiles = ({ files, setFiles, maxCount = MAX_COUNT, format, previews }) => {
  const { handleFilesChange, removeFile, error } = useUploadFiles({ files, setFiles, maxCount });
  const disabledUploadButton = files.length >= maxCount;
  const showPreviews = files.length === 0 && previews?.length > 0;

  return (
    <div className={css.wrap}>
      <label className={css.label}>
        <input
          multiple
          accept={format ? format : 'audio/mp3'}
          type="file"
          onChange={handleFilesChange}
          disabled={disabledUploadButton}
          className="d-none"
        />
        <Button
          variant="outlined"
          color="secondary"
          aria-label="upload"
          disabled={disabledUploadButton}
          className={css.button}
          endIcon={<FileUpload />}
          component="span">
          Додати файл
        </Button>
      </label>

      {files.length > 0
        ? files.map((file, index) => (
            <UploadFilesPreview key={index} file={file} onClose={removeFile} index={index} />
          ))
        : null}

      {showPreviews
        ? previews.map(({ name, link }) => (
            <AttachedFilesPreview key={link} name={name} link={link} />
          ))
        : null}

      {error && <div className={css.error}>{error}</div>}
    </div>
  );
};

export default UploadFiles;
