import { useState } from 'react';
import appRoutes from '../../../config/appRoutes';

//modules
import { UploadFiles } from '../../../modules/UploadFiles';
import { UploadImage } from '../../../modules/UploadImage';

//hooks
import { useFetchTaxonomies, useBooksQueries } from './hooks';

//ui
import { PageTitle, LinkComeBack, BlockSpinner, CustomSnackbar } from '../../../components';
import { BooksForm } from './components';
import css from './Books.module.css';

const CreateBookPage = () => {
  const [image, setImage] = useState(null);
  const [tracks, setTracks] = useState([]);
  const { genres, authors, narrators, loading } = useFetchTaxonomies();
  const { status, message, createBook } = useBooksQueries({ image, tracks });

  return (
    <>
      <LinkComeBack to={appRoutes.BOOKS.url} />
      <PageTitle title={appRoutes.BOOKS_CREATE.name} />
      <div className={css.grid}>
        <BooksForm genres={genres} authors={authors} narrators={narrators} onSubmit={createBook} />

        <aside>
          <div className={css.aside_box}>
            <div className={css.title}>Зображення</div>
            <div className={css.content}>
              <UploadImage file={image} setFile={setImage} />
            </div>
          </div>
          <div className={css.aside_box}>
            <div className={css.title}>Аудіо файли</div>
            <div className={css.content}>
              <UploadFiles files={tracks} setFiles={setTracks} />
            </div>
          </div>
        </aside>
      </div>
      {status === 'rejected' ? <CustomSnackbar text={message} /> : null}
      <BlockSpinner show={loading || status === 'pending'} />
    </>
  );
};

export default CreateBookPage;
